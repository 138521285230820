<template>
    <div id="log_manage">
        <jy-query ref="form" :model="form">
            <jy-query-item prop="userNa" label="登录账号:">
                <el-input placeholder="请输入" v-model="form.userNa"></el-input>
            </jy-query-item>
            <jy-query-item prop="markNa" label="用户名称:">
                <el-input placeholder="请输入" v-model="form.markNa"></el-input>
            </jy-query-item>
            <template slot="rightCol">
                <el-button type="primary" @click="oncheck" v-if="btnexist('scheduleSystemSettingsLogonLogList')">查询</el-button>
                <el-button type="primary" @click="resetForm('form')" v-if="btnexist('scheduleSystemSettingsLogonLogReset')">重置</el-button>
                <el-row>
                    <!-- <el-button type="primary">导出</el-button> -->
                </el-row>
            </template>
        </jy-query>
        <jy-table ref="jyTable" :data="dataList">
            <jy-table-column type="index" label="序号" width="60"></jy-table-column>
            <jy-table-column prop="loginUser" label="登录账号"></jy-table-column>
            <jy-table-column prop="loginNa" label="用户名称"></jy-table-column>
            <jy-table-column prop="orgNa" label="所属机构"></jy-table-column>
            <jy-table-column prop="roleNa" label="所属角色"></jy-table-column>
            <jy-table-column prop="loginIp" label="所属IP"></jy-table-column>
            <jy-table-column prop="loginT" label="系统登录时间"></jy-table-column>
            <!-- <jy-table-column prop="logoutT" label="系统退出时间"></jy-table-column> -->
        </jy-table>
        <jy-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="pageIndex" :page-size="pageSize" :total="total">
        </jy-pagination>
    </div>
</template>
<script>
import {btnMixins} from '@/common/js/button.mixin'
export default {
    data() {
        return {
            form: {
                userNa: '',
                markNa: ''
            },
            pageSize: 10,
            total: 100,
            pageIndex: 1,
            dataList: [],

            btnMenuId:'scheduleSystemSettingsLogonLog',
        }
    },
    mixins:[btnMixins],
    created() {
        this.getList()
    },
    methods: {
        getList() {
            let option = {
                ...this.form,
                pageIndex: this.pageIndex,
                pageSize: this.pageSize
            }
            let url = '/system/userLog/queryUserLoginLog'
            console.log(option)
            this.$http.post(url, option).then(({ detail }) => {
                console.log(detail)
                this.dataList = detail.list
                this.total = detail.total
            })
        },
        // 查询表格
        oncheck() {
            this.pageIndex = 1
            this.getList()
            console.log('更新')
        },
        // 重置
        resetForm(formName) {
            this.$refs[formName].resetFields()
            this.oncheck()
        },
        // 分页
        handleSizeChange(val) {
            this.pageSize = val
            this.getList()
        },
        handleCurrentChange(val) {
            this.pageIndex = val
            this.getList()
        }
    }
}

</script>
